<template>
	<div class="page" v-loading="loading">
		<gc-header headerData="gasConsumptionAnalysis">
			<template #ops>
				<el-button type="primary" @click="handleContrast">对比(已选{{ contrastList.length }}个)</el-button>
			</template>
		</gc-header>
		<div class="run-main">
			<div class="serach">
				<gc-custom-search
					v-for="item in searchCondition"
					:key="item.key"
					:keyWord="item.label"
					:width="item.width || '300px'"
					:search.sync="form[item.key]"
					:type="item.type"
					:searchOption="options[item.key]"
					:needAllForSearch="item.needAllForSearch"
					:searchOptionKey="item.searchOptionKey"
					:required="item.required"
				></gc-custom-search>
				<div class="serach-ops">
					<el-button type="primary" @click="getList()" v-click-blur>查 询</el-button>
					<el-button @click="reset" v-click-blur>重 置</el-button>
				</div>
				<gc-column-setting
					:storageKey="`localcolumn-gas-consumption-analysis`"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table-show">
				<gc-table
					:columns="columnList"
					:table-data="tableData"
					:border="true"
					:showPage="true"
					:total="page.total"
					:page-size="page.size"
					:currentPage="page.current"
					@current-page-change="currentPageChange"
					@size-change="handleSizeChange"
				></gc-table>
			</div>
		</div>
		<contrast-list
			class="contrast-list"
			:contrast-data="contrastList"
			:contrastVisible.sync="isShowContrast"
			@deleteContrast="deleteContrast"
		></contrast-list>
	</div>
</template>

<script>
import { getGasAnalyzList } from '@/apis/alarm.api.js'
import { deviceDetailPermissions } from '@/config/const'
import { isBlank } from '@/utils/validate'
import { handleParams } from '@/utils'
import ContrastList from './components/ContrastList'

export default {
	name: 'gasConsumptionAnalysis',
	components: { ContrastList },
	data() {
		return {
			form: {
				deviceNo: '',
				manufacturerName: '',
				address: '',
				userTypeId: '',
			},
			contrastList: [],
			contrastNumber: 0,
			isShowContrast: false,
			page: {
				total: 0,
				size: 20,
				current: 1,
			},
			options: {
				userTypeId: [],
			},
			searchCondition: [
				{
					key: 'deviceNo',
					label: '设备编号',
				},
				{
					key: 'manufacturerName',
					label: '厂商名称',
				},
				{
					key: 'address',
					label: '地址',
				},
				{
					key: 'userTypeId',
					label: '客户类型',
					type: 'select',
					searchOptionKey: {
						label: 'name',
						value: 'defaultValue',
					},
				},
			],
			columns: [
				{
					key: 'deviceNo',
					name: '设备编号',
					tooltip: true,
					fixed: 'left',
					dragDisable: true,
					disabledSelect: true,
				},
				{
					key: 'deviceTypeName',
					name: '设备类型',
					tooltip: true,
				},
				{
					key: 'isAlarm',
					name: '设备状态',
					render: (h, row) => {
						let val = this.chooseStatus(row)
						const statusMap = {
							告警: 'dot-warning',
							故障: 'dot-error',
							正常: 'dot-success',
							离线: 'dot-message',
						}
						return h('span', { class: isBlank(val) ? '' : statusMap[val] }, isBlank(val) ? '--' : val)
					},
				},
				{
					key: 'manufacturerName',
					name: '厂商名称',
					tooltip: true,
				},
				{
					key: 'userType',
					name: '客户类型',
					render: (h, row) => {
						const findItem = this.options.userTypeId.find(item => {
							return row.userType == item.defaultValue
						})
						let content = '--'
						if (findItem) {
							content = findItem.name
						}
						return h('span', {}, content)
					},
				},
				{
					key: 'addressName',
					name: '地址',
					tooltip: true,
				},
				{
					key: 'nominalDiameter',
					name: '流量规格',
				},
				{
					key: 'installDate',
					name: '通气时间',
				},
				{
					key: 'communicateTime',
					name: '最后通讯时间',
					minWidth: 180,
				},
				{
					key: 'workCumulativeFlow',
					name: '工况总量',
				},
				{
					key: 'standardCumulativeFlow',
					name: '标况总量',
				},
				{
					key: 'oprate',
					name: '操作',
					width: '250px',
					fixed: 'right',
					render: (h, row) => {
						// 根据安装状态进行动态变化
						// 如果状态是异常则展示或者没有任何操作权限  /
						// if (isBlank(row.deviceStatus)) {
						//   return h("span", { style: { color: "#CCCCCC" } }, "/");
						// }
						const { contrastDisabled } = row
						let style = {
							'font-size': '14px',
							'font-family': 'SourceHanSansCN-Regular, SourceHanSansCN',
							'font-weight': '400',
							color: '#4A69FF',
							'line-height': '21px',
							'margin-right': '20px',
							cursor: 'pointer',
						}
						return h('div', {}, [
							...[
								this.$has(deviceDetailPermissions)
									? [
											h(
												'span',
												{
													on: {
														click: () => this.contrast(row),
													},
													style,
												},
												'气量分析',
											),
											h(
												'span',
												{
													on: {
														click: () => this.addContrast(row),
													},
													style: {
														...style,
														color: contrastDisabled ? '#9A9EB3' : '#4A69FF',
														cursor: contrastDisabled ? 'not-allowed' : 'pointer',
													},
												},
												'加入对比',
											),
									  ]
									: [],
							],
						])
					},
				},
			],
			tableData: [],
			visible: false,
			loading: false,
		}
	},
	methods: {
		reset() {
			this.form = {}
			this.getList()
		},
		handleSizeChange(size) {
			this.page.size = size
			this.getList(1)
		},
		getList(currnetPage) {
			this.page.current = currnetPage || 1
			let data = {
				...this.page,
			}
			for (var key in this.form) {
				let val = handleParams(this.form)[key]
				if (!isBlank(val)) {
					data[key] = val
				} else {
					data[key] = null
				}
			}
			if (data.address && data.address.length < 2) {
				this.$message.warning('地址不可以少于2个字符')
				return
			}
			this.loading = true
			getGasAnalyzList(data)
				.then(res => {
					this.page.total = res.total
					this.page.current = res.current
					this.tableData = res.records || []
					this.chooseOldData()
				})
				.finally(() => {
					this.loading = false
				})
		},
		chooseOldData() {
			this.tableData = this.tableData.map(item => {
				this.$set(item, 'contrastDisabled', false)
				this.contrastList.forEach(i => {
					if (item.deviceId === i.deviceId) {
						this.$set(item, 'contrastDisabled', true)
					}
				})
				return item
			})
		},
		handleContrast() {
			if (!this.contrastList.length) return
			this.isShowContrast = true
		},
		// 翻页
		currentPageChange(currentPage) {
			this.getList(currentPage)
		},
		contrast(row) {
			this.$router.push({
				name: 'singleGasConsumptionAnalysis',
				query: {
					deviceId: row.deviceId,
				},
			})
		},
		addContrast(row) {
			if (row.contrastDisabled) return
			if (this.contrastList.length === 5) {
				this.$message.warning('最多选取5条数据！')
				return
			}
			this.$set(row, 'contrastDisabled', true)
			this.contrastList.push(row)
		},
		deleteContrast(row, contrastList) {
			this.contrastList = contrastList
			this.chooseOldData()
		},
		chooseStatus(item) {
			let statusFlag = '正常'
			const { isAlarm, isAlarmFaulty, isAlarmOffline } = item
			if (isAlarmFaulty == '0' || !isAlarmFaulty) {
				if (isAlarm == '1') {
					statusFlag = '告警'
				} else {
					if (isAlarmOffline == '1') {
						statusFlag = '离线'
					} else {
						statusFlag = '正常'
					}
				}
			} else {
				statusFlag = '故障'
			}
			return statusFlag
		},
	},
	created() {
		this.getList()
		this.options.userTypeId = this.$store.getters?.dataList?.userType || []
	},
	computed: {
		columnList() {
			return this.columns.filter(o => o.selected)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
.contrast-list {
	position: fixed;
	top: 80px;
	right: 20px;
}
</style>
